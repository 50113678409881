<template>
  <div>
    <b-container>
      <b-row>
        <b-col>
          <master-title>Adhésion</master-title>
          <div class="tb-btns">
            <b-button href="/faire-un-don">
              <font-awesome-icon
                style="color: #e1c726;"
                size="2x"
                icon="fa-solid fa-coins"
              />
              Faites un don au projet
            </b-button>
            <b-button href="https://www.helloasso.com/associations/tim-et-bastien/adhesions/adhesion-annuelle-1" target="_blank">
              <font-awesome-icon
                style="color: #FC9B37;"
                size="2x"
                icon="fa-solid fa-users"
              />
              Adhérez à l'association
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row class="connect-row justify-content-md-center">
        <b-col lg="12">
          <master-title>Newsletter</master-title>
        </b-col>
        <b-col lg=4>
          <newsletter/>
        </b-col>
      </b-row>
      <b-row class="connect-row justify-content-md-center" v-if="$store.state.is_connected">
        <b-col lg="12">
          <master-title>Espace administrateur.ice</master-title>
        </b-col>
        <b-col lg=4 v-if="$store.state.is_connected">
          <user-info/>
        </b-col>
      </b-row>
      <b-row class="connect-row justify-content-md-center" v-if="!$store.state.is_connected">
        <b-col lg="12">
          <master-title>Connection administrateur.ice</master-title>
        </b-col>
        <b-col lg=4>
          <connect/>
        </b-col>
      </b-row>
      <!-- <b-row class="connect-row justify-content-md-center" v-if="!$store.state.is_connected">
        <b-col lg=4>
          <create-account/>
        </b-col>
      </b-row> -->
      <admin-base v-if="$store.state.is_admin"/>
      <div style="min-height: 1rem;"></div>
      <tb-footer />
    </b-container>
  </div>
</template>

<script>
import MasterTitle from '@/components/utils/MasterTitle.vue'
import Newsletter from '@/components/login/Newsletter.vue'
import Connect from '@/components/login/Connect.vue'
// import CreateAccount from '@/components/login/CreateAccount.vue'
import UserInfo from '@/components/login/UserInfo.vue'
import AdminBase from '@/components/admin/AdminBase.vue'
import TbFooter from '@/components/utils/TbFooter.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoins, faUsers, faCarrot } from '@fortawesome/free-solid-svg-icons'

library.add(faCoins)
library.add(faUsers)
library.add(faCarrot)

export default {
  name: 'Login',
  components: {
    MasterTitle,
    Newsletter,
    Connect,
    // CreateAccount,
    UserInfo,
    AdminBase,
    TbFooter,
  },
  data () {
    return {
    }
  },
  mounted () {
  },
}
</script>

<style scoped>
.connect-row {
  margin-top: 5px;
}
</style>
