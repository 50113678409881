<template>
  <b-row class="footer-view full-width-row">
    <b-col class="footer-col-left">
      <img src="@/../public/assets/new_logo_black.png" class="footer-logo" alt="Tim & Bastien">
    </b-col>
    <b-col class="footer-col-center">
      <div class="footer-link">
        <div>
          <a href="/association">L'association</a>
          <a href="/le-food-truck">Le food truck</a>
        </div>
        <div>
          <a href="https://www.helloasso.com/associations/tim-et-bastien/adhesions/adhesion-annuelle-1" target="_blank">
            Adhésion
          </a>
          <a href="/contact">Contact</a>
        </div>
        <div>
          <a href="/mentions-legales">Mentions légales</a>
          <a href="/cgu">CGU</a>
        </div>
      </div>
      <div class="social-networks">
        <a href="https://www.facebook.com/timetbastien" target="_blank">
          <b-icon icon="facebook" font-scale=2 />
        </a>
        <a href="https://www.instagram.com/timetbastien" target="_blank">
          <b-icon icon="instagram" font-scale=2 />
        </a>
      </div>
    </b-col>
    <b-col class="footer-col-right">
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "TbFooter",
}
</script>

<style scoped>
.footer-view {
  background-color: var(--color-green-light);
}
.footer-col-left {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-col-center {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.footer-col-right {
  min-width: 10rem;
}

.footer-link {
  /* align-self: center; */
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  /* justify-content: space-around; */
  /* align-content: space-around; */
  padding-bottom: 1rem;
}
.footer-link>div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-link>div>a {
  text-decoration: none;
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
}
.footer-link a:link { color: var(--color-grey-1); }  /* unvisited link */

.footer-link a:visited { color: var(--color-grey-1); }  /* visited link */

.footer-link a:hover { color: var(--color-grey-2); }  /* mouse over link */

.footer-link a:active { color: var(--color-grey-2); }  /* selected link */

.social-networks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: space-around;
  padding-bottom: 1rem;
}
.social-networks>* {
  margin-right: 1rem;
  margin-left: 1rem;
  color: inherit;
}

.footer-logo {
  max-width: 10rem;
}
</style>