<template>
  <div id="tb-navbar-full">
    <div id="tb-topbar"></div>
    <b-navbar id="tb-navbar" class="shadow" toggleable="lg">

      <b-navbar-brand href="/" class="top-menu-img">
        <img src="@/../public/assets/new_logo_black.png" class="d-inline-block align-top" alt="Tim & Bastien">
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/association">L'association</b-nav-item>
          <!-- <b-nav-item-dropdown text="L'association" toggle-class="tb-nav-dropdown">
            <b-dropdown-item href="/association">Présentation</b-dropdown-item>
            <b-dropdown-item href="/nos-partenaires">Nos partenaires</b-dropdown-item>
          </b-nav-item-dropdown> -->

          <b-nav-item-dropdown text="Restaurer" toggle-class="tb-nav-dropdown">
            <b-dropdown-item href="/le-food-truck">Le food truck</b-dropdown-item>
            <b-dropdown-item href="/le-menu">Le menu</b-dropdown-item>
            <b-dropdown-item href="/recettes">Nos recettes</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item to="/sensibiliser">Sensibiliser</b-nav-item>
          <b-nav-item to="/accompagner">Accompagner</b-nav-item>

          <!-- <b-nav-item to="/contact">Contact</b-nav-item> -->
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <!-- <b-nav-item-dropdown text="L'association (old)">
            <b-dropdown-item href="/notre-histoire">Notre histoire</b-dropdown-item>
            <b-dropdown-item href="/ils-parlent-de-nous">Ils parlent de nous</b-dropdown-item>
            <b-dropdown-item href="/faire-un-don">Faire un don</b-dropdown-item>
            <b-dropdown-item href="/nos-partenaires">Nos partenaires</b-dropdown-item>
            <b-dropdown-item href="/documents">Documents</b-dropdown-item>
          </b-nav-item-dropdown> -->

          <!-- <b-nav-item-dropdown text="S'engager (old)">
            <b-dropdown-item href="/devenir-adherent">Devenir adhérent</b-dropdown-item>
            <b-dropdown-item href="/nous-recrutons">Nous recrutons</b-dropdown-item>
            <b-dropdown-item href="/accompagnement-de-projet">Accompagnement de projet</b-dropdown-item>
          </b-nav-item-dropdown> -->

          <!-- <b-nav-item href="https://www.helloasso.com/associations/tim-et-bastien/adhesions/adhesion-annuelle-1" target="_blank">
            Adhérer
          </b-nav-item> -->

          <b-nav-item-dropdown v-if="$store.state.is_admin" no-caret right>
            <template #button-content>
              <b-icon icon="gear-fill" font-scale="2"></b-icon>
            </template>
            <b-dropdown-header>Cuisine</b-dropdown-header>
            <b-dropdown-item href="/admin/recettes">Recettes</b-dropdown-item>
            <b-dropdown-item href="/admin/menus">Menus</b-dropdown-item>
            <b-dropdown-item href="/admin/liste-courses">Liste de courses</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-header>Autres</b-dropdown-header>
            <b-dropdown-item href="/admin/compte">Compte</b-dropdown-item>
            <b-dropdown-item href="/admin/destinations">Destinations</b-dropdown-item>
            <b-dropdown-item href="/admin/media">Media</b-dropdown-item>
            <b-dropdown-item href="/admin/utilisateurs">Adhérents</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item to="/login">
            <b-icon icon="person-check-fill" font-scale="2" v-if="$store.state.is_connected"></b-icon>
            <b-icon icon="person" font-scale="2" v-else></b-icon>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div style="height:0.5rem;"></div>
  </div>
</template>

<script>
import { sock_emit } from '@/plugins/utils.js'

export default {
  name: "TbNavBar",
	data () {
		return {
			interval: null,
		}
	},
	mounted () {
		document.title = "Tim & Bastien"
		this.interval = setInterval(this.recurentCallback, this.$store.state.connect_interval);
		this.recurentCallback();
	},
  destroyed() {
    clearInterval(this.interval)
  },
	sockets: {
		is_user_connected_resp: function (val) {
			if (this.$store.state.is_connected) {
				if (val["is_connected"] == false) {
					this.logout()
				}
				else if (this.$store.state.is_admin != val['is_admin']) {
					this.$store.state.is_admin = val['is_admin']
				}
			}
		}
	},
	methods: {
		recurentCallback: function () {
			this.updateIsConnected()
		},
		logout: function () {
			this.$store.commit('LOGOUT_SUCCESS')
			if (window.location.pathname.startsWith("/admin")) {
				this.$router.push("/login")
			}
		},
		updateIsConnected: function() {
			if (this.$store.state.is_connected) {
				sock_emit("is_user_connected", {
					username: this.$store.state.username,
					token: `${this.$store.state.bearer_token}`,
				})
			}
			else {
				if (window.location.pathname.startsWith("/admin")) {
					this.$router.push("/login")
				}
			}
		},
	}
};
</script>

<style scoped>
#tb-navbar-full {
	z-index: 999;
  position: sticky;
  top: 0;
}

#tb-topbar {
  width: 100%;
  height: 0.3rem;
  background-color: var(--color-green-dark);
}

#tb-navbar {
	background-color: var(--color-green-light);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.top-menu-img a {
	padding-top: 0;
	padding-bottom: 0;
}
.top-menu-img img {
	height: 3rem;
}
</style>