<template>
  <b-row>
    <b-col>
      <master-title>Et vous ?</master-title>
      <div class="tb-btns">
        <!-- <b-button href="/recettes">
          <font-awesome-icon
            style="color: #ffd4b1;"
            size="2x"
            icon="fa-solid fa-cookie-bite"
          />
          Testez nos délicieuses recettes
        </b-button> -->
        <!-- <b-button href="/association">
          <font-awesome-icon
            style="color: #ED9121;"
            size="2x"
            icon="fa-solid fa-carrot"
          />
          Découvrez l'association
        </b-button> -->
        <!-- <b-button href="/nous-recrutons">
          <font-awesome-icon
            style="color: #FC9B37;"
            size="2x"
            icon="fa-solid fa-users"
          />
          Devenez salarié ou bénévole chez Tim & Bastien
        </b-button> -->
        <b-button href="/faire-un-don">
          <font-awesome-icon
            style="color: #e1c726;"
            size="2x"
            icon="fa-solid fa-coins"
          />
          Faites un don au projet
        </b-button>
        <b-button href="https://www.helloasso.com/associations/tim-et-bastien/adhesions/adhesion-annuelle-1" target="_blank">
          <font-awesome-icon
            style="color: #FC9B37;"
            size="2x"
            icon="fa-solid fa-users"
          />
          Adhérez à l'association
        </b-button>
        <b-button href="/login">
          <font-awesome-icon
            style="color: rgb(148 104 209);"
            size="2x"
            icon="fa-solid fa-envelope"
          />
          Abonnez-vous à notre newsletter
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCookieBite, faCoins, faUsers, faEnvelope, faCarrot } from '@fortawesome/free-solid-svg-icons'

library.add(faCookieBite)
library.add(faCoins)
library.add(faUsers)
library.add(faEnvelope)
library.add(faCarrot)

import MasterTitle from '../utils/MasterTitle.vue'
export default {
  components: { MasterTitle },
  name: "EtVous",
}
</script>

<style scoped>
</style>